<template>
  <div class="container-fluid monitor">
  <!--  <LoaderComponent v-if="loading" />
    <div class="container">
      <div class="row justify-content-center">
        <h1>Revisor Real Time</h1>
      </div>
      <div class="row datepicker">
         <date-range-picker
                v-model="dateRange"
                @update="getbyPeriod()"
              ></date-range-picker>
            
      </div>
      <div class="row">
        <div class="col-md-4">
          <Barchart
            v-if="saveChart != null"
            :updated="updated"
            :chartData.sync="saveChart.line.data"
            :chartOptions.sync="saveChart.options"
            :styles.sync="saveChart.line.styles"
          />
        </div>
        <div class="col-md-4">
          <Barchart
            v-if="onusChart != null"
            :updated="updated"
            :chartData.sync="onusChart.line.data"
            :chartOptions.sync="onusChart.options"
            :styles.sync="onusChart.line.styles"
          />
        </div>
        <div class="col-md-4">
          <Barchart
            v-if="ownersChart != null"
            :updated="updated"
            :chartData.sync="ownersChart.line.data"
            :chartOptions.sync="ownersChart.options"
            :styles.sync="ownersChart.line.styles"
          />
        </div>
      </div>
      <div class="row monitor-lists">
        <ListAnalytics
          v-if="matriculasOpened.linhas.length > 0"
          :title="`Matrículas Abertas - ${matriculasOpened.total}`"
          :rows.sync="matriculasOpened.linhas"
          :updated.sync="updated"
          :type="'matriculasOpened'"
          :numberpages="10"
        />

        <ListAnalytics
          v-if="savedByuser.length > 0"
          :title="`Matrículas Salvas por usuário`"
          :rows.sync="savedByuser"
          :type="'savedByuser'"
          :updated.sync="updated"
          :numberpages="10"
        />

        <ListAnalytics
          v-if="matriculasSaved.linhas.length > 0"
          :title="`Matrículas Salvas - ${matriculasSaved.total}`"
          :rows.sync="matriculasSaved.linhas"
          :type="'matriculasSaved'"
          :updated.sync="updated"
          :numberpages="10"
        />

        <ListAnalytics
          v-if="onusTotal !== undefined && onusTotal.length != 0"
          :title="`Ônus - Adicionados:${onusAdded.total} - Removidos:${onusRemoved.total}`"
          :rows.sync="onusTotal"
          :type="'onusTotal'"
          :updated.sync="updated"
          :numberpages="5"
        />

        <ListAnalytics
          v-if="ownersTotal !== undefined && ownersTotal.length != 0"
          :title="`Compradores - Adicionados:${ownersAdded.total} - Removidos:${ownersRemoved.total}`"
          :rows.sync="ownersTotal"
          :type="'ownersTotal'"
          :updated.sync="updated"
          :numberpages="5"
        />

       
      </div>
    </div> -->
  </div>
</template>
<script type="text/javascript">
import { MonitorRevisorv2Mixin } from "./MonitorRevisorv2.mixin.js";
import Barchart from "../../components/ui/Barchart/Barchart";
import ListAnalytics from "../../components/ui/list-analytics/list-analytics";
import DateRangePicker from "vue2-daterange-picker";
import LoaderComponent from "../../components/ui/loader/loader";
import ListComponent from "../../components/ui/list-analytics/list-analytics";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {};
  },
  watch: {},
  props: {},
  components: {
    Barchart,
    DateRangePicker,
    LoaderComponent,
    ListComponent,
    ListAnalytics,
  },
  name: "MonitorRevisorv2",
  mixins: [MonitorRevisorv2Mixin],
};
</script>
<style src="./MonitorRevisorv2.less" lang="less" />
