import { ApiService, Apimatriculas } from '@/axios'

export const getMatriculas_an = () => {
    return Apimatriculas.getAll('matriculas_fromanalytics')
}

export const getMatriculas_andata = (data) => {
    return Apimatriculas.post('matriculas_fromanalytics', data)
}

export const getMatriculas_pages = (data) => {
    return Apimatriculas.post('matriculas_pages', data)
}

export const getRevisor_an = (data) => {
    return ApiService.post('analytics_revisor', data)
}

export const getRevisor_an_v1 = (data) => {
    return ApiService.post('analytics_revisor_v1', data)
}