<template>
    <div class="container fieldList">
      <h2>{{ title }}</h2>
      <table class="table table-striped" v-if="type === 'matriculasSaved'">
        <thead>
          <tr>
            <th scope="col">Hora</th>
            <th scope="col">Matricula ID</th>
            <th scope="col">Usuário</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>
              {{ list.label | savedHora}}
            </td>
            <td>{{ list.label | savedMatriculaID }}</td>
            <td>{{ list.label | savedUser }}</td>

          </tr>
        </tbody>
      </table>
       <table class="table table-striped" v-else-if="type === 'matriculasOpened'">
        <thead>
          <tr>
            <th scope="col">Batch</th>
            <th scope="col">Matrícula</th>
            <th scope="col">Aberturas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>
              {{ list.label | openBatchID }}
            </td>
              <td>
              {{ list.label | openMatriculaID }}
            </td>
            <td>{{ list.total }}</td>

          </tr>
        </tbody>
      </table>
        <table class="table table-striped" v-else-if="type === 'savedByuser'">
        <thead>
          <tr>
            <th scope="col">Usuário</th>
            <th scope="col">Matrículas Salvas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>
              {{ list.nome}}
            </td>
              <td>
              {{ list.total }}
            </td>
          

          </tr>
        </tbody>
      </table>

       <table class="table table-striped" v-else>
        <thead>
          <tr>
            <th scope="col">Matrícula</th>
            <th scope="col">Adicionados</th>
            <th scope="col">Removidos</th>
             <th scope="col">Alterações</th>
             <th scope="col">Model Extract</th>
             <th scope="col">% Remoção</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>
              {{ list.matricula }}
            </td>
            <td>{{ list.totalAdded }}</td>
             <td>{{ list.totalRemoved }}</td>
             <td>{{ list.total }}</td>
             <td>{{ list.totalModel }}</td>
             <td>{{ list.percentremoved }}%</td>

          </tr>
        </tbody>
      </table>
       <div class="row" v-if="paginationType === 'fast'">
        <div class="pagination">
          <a
            v-for="(page, key) in pages"
            :key="key"
            :class="`${page.status}`"
            @click="changePage(page.page)"
            >{{ page.page }}</a
          >
        </div>
      </div>
      <div class="row pages" v-if="paginationType === 'long'">
        <div class="col-md-2">
          <div class="pagination">
            <a :class="`${pages[0].status}`" @click="previousPage()">{{
              pages[0].page
            }}</a>
            <a :class="`${pages[1].status}`" @click="nextPage()">{{
              pages[1].page
            }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <h3>{{ pageLongIndex }} de {{ totalPages }} páginas</h3>
        </div>
        <div class="col-md-4">
          <input
            class="form-group"
            type="text"
            v-model="searchPage"
            placeholder="Procurar página..."
            @change="inputChange(searchPage)"
          />
        </div>
      </div>
    </div>

</template>

<script type="text/javascript">
import { ListAnalyticsMixin } from "./list-analytics.mixin";
export default {
  methods: {},
  name: "ListAnalytics",
  mixins: [ListAnalyticsMixin],
};
</script>

<style src="./list-analytics.less" lang="less" />
